import { PlatformOverviewItemCollection } from './../../../model/types/platformOverviewTypes';
import { fetchAll as fetchAllPlatforms } from '../../../contentful/repository/platformRepository';
import { useState, useEffect } from 'react';
import { createEmpty as createEmptyCollection } from '../../../model/factory/paginatedCollectionFactory';

export const noOfResultsPerPage = 12;

export default function useFetchPlatformOverviewItems(
    currentPage: number,
    activeSectors: Array<string>,
    activeServiceTypes: Array<string>,
    activeClientTypes: Array<string>
) {
    const [collection, setCollection] = useState<
        PlatformOverviewItemCollection
    >(createEmptyCollection());

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        const skip = (currentPage - 1) * noOfResultsPerPage;

        fetchAllPlatforms(
            skip,
            noOfResultsPerPage,
            activeSectors,
            activeServiceTypes,
            activeClientTypes
        )
            .then((collection) => setCollection(collection))
            .catch((error) => {
                // @todo notify user
                // @todo log error to logging service

                console.error('error', error);
            })
            .finally(() => setIsLoading(false));
    }, [currentPage, activeSectors, activeServiceTypes, activeClientTypes]);

    return { collection, isLoading, currentPage };
}
