import { useState } from 'react';

export enum ServiceTypeOptions {
    online = 'Online',
    offline = 'Offline',
}

export enum ClientTypeOptions {
    personal = 'Persoonlijk',
    business = 'Zakelijk',
}

export type OnFilterChangeCallback = (input: HTMLInputElement) => void;

export const usePlatformFiltering = (resetCurrentPage: () => void) => {
    const [activeSectors, setActiveSectors] = useState<Array<string>>([]);

    const [activeServiceTypes, setActiveServiceTypes] = useState<Array<string>>(
        []
    );

    const [activeClientTypes, setActiveClientTypes] = useState<Array<string>>(
        []
    );

    const applyFilterUpdate = (
        input: HTMLInputElement,
        activeFilters: Array<string>,
        setFilters: React.Dispatch<React.SetStateAction<Array<string>>>
    ) => {
        // as the filters change, we logically want the user to see the first page of the results first
        resetCurrentPage();

        const filterValue: string = input.value;
        const checked: boolean = input.checked;

        setFilters((currentFilters) => {
            if (checked) {
                if (activeFilters.includes(filterValue)) {
                    return currentFilters;
                }
                return [...currentFilters, filterValue];
            }

            if (!currentFilters.includes(filterValue)) {
                return currentFilters;
            }

            return currentFilters.filter(
                (cursorFilter) => cursorFilter !== filterValue
            );
        });
    };

    const handleSectorsFilterUpdate: OnFilterChangeCallback = (
        input: HTMLInputElement
    ) => {
        return applyFilterUpdate(input, activeSectors, setActiveSectors);
    };

    const handleServiceTypesFilterUpdate = (input: HTMLInputElement) => {
        return applyFilterUpdate(
            input,
            activeServiceTypes,
            setActiveServiceTypes
        );
    };

    const handleClientTypesFilterUpdate = (input: HTMLInputElement) => {
        return applyFilterUpdate(
            input,
            activeClientTypes,
            setActiveClientTypes
        );
    };

    const handleResetAllActiveFilters = () => {
        setActiveSectors([]);
        setActiveServiceTypes([]);
        setActiveClientTypes([]);
    };

    return {
        activeSectors,
        handleSectorsFilterUpdate,
        activeServiceTypes,
        handleServiceTypesFilterUpdate,
        activeClientTypes,
        handleClientTypesFilterUpdate,
        handleResetAllActiveFilters,
    };
};
