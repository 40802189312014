import useToggleVisibility from '../../../hooks/useToggleVisibility';
import { useContext } from 'react';
import MatchMediaContext from 'react-match-media-context';
import { BreakpointsConfigMatch } from '../../../constants/breakpoints';
import { isBrowser } from '../../../utility/environmentUtilities';

export default function useMainNavigationVisibility() {
    const matchingBreakpoints = useContext<BreakpointsConfigMatch>(
        MatchMediaContext
    );

    const hasMobileMenu =
        isBrowser && (matchingBreakpoints.xs || matchingBreakpoints.sm);

    const { isVisible: isMenuOpen, toggleVisibility } = useToggleVisibility(
        !hasMobileMenu
    );

    return { hasMobileMenu, isMenuOpen, toggleVisibility };
}
