import { createQueryString } from '../utility/queryStringUtilities';

export const homePath = '/';

export enum PageSlug {
    Contact = 'contact',
    About = 'over-platformwerk-nl',
    EnglishAbout = 'about-platformwerk-nl',
    CollaborativeEconomy = 'kluseconomie',
}

export const createPageDetailPath = (slug: PageSlug) => `/${slug}`;

export const createHomePathWithFilters = (page: number) =>
    homePath + createQueryString({ page });

// BEWARE! Keep inline with url generation in gatsby-node.js
export const createPlatformDetailPath = (slug: string) => `/platformen/${slug}`;
