/* eslint-disable @typescript-eslint/no-empty-function */

import React, { createContext, useContext } from 'react';
import { PlatformOverviewItemCollection } from '../../model/types/platformOverviewTypes';
import { createEmpty as createEmptyCollection } from '../../model/factory/paginatedCollectionFactory';
import useFetchPlatformOverviewItems from './hooks/useFetchPlatformOverviewItems';
import useManageCurrentPageState from './hooks/useManageCurrentPageState';
import { OnPageChangeCallback } from '../../components/primitives/pagination/Pagination';
import useStoreLastScrollPosition, {
    ScrollPosition,
} from './hooks/useStoreLastScrollPosition';
import {
    OnFilterChangeCallback,
    usePlatformFiltering,
} from '../../components/platformOverviewFilters/hooks/usePlatformFiltering';

type ContextValue = {
    isLoading: boolean;
    collection: PlatformOverviewItemCollection;
    onPageChange: OnPageChangeCallback;
    currentPage: number;
    lastScrollPositionRef: React.MutableRefObject<ScrollPosition> | null;
    resetLastScrollPosition: () => void;
    onSectorsFilterChange: OnFilterChangeCallback;
    onServiceTypesFilterChange: OnFilterChangeCallback;
    onClientTypesFilterChange: OnFilterChangeCallback;
    resetAllActiveFilters: () => void;
    activeSectors: string[];
    activeServiceTypes: string[];
    activeClientTypes: string[];
};

const initialValue: ContextValue = {
    isLoading: false,
    collection: createEmptyCollection(),
    onPageChange: () => {},
    currentPage: 1,
    lastScrollPositionRef: null,
    resetLastScrollPosition: () => {},
    onSectorsFilterChange: () => [],
    onServiceTypesFilterChange: () => [],
    onClientTypesFilterChange: () => [],
    resetAllActiveFilters: () => {},
    activeSectors: [],
    activeServiceTypes: [],
    activeClientTypes: [],
};

const PlatformOverviewContext = createContext<ContextValue>(initialValue);

export const PlatformOverviewContextProvider: React.FC<{
    children: JSX.Element;
}> = ({ children }) => {
    const {
        positionRef: lastScrollPositionRef,
        reset: resetLastScrollPosition,
    } = useStoreLastScrollPosition();

    const {
        onPageChange,
        currentPage,
        resetCurrentPage,
    } = useManageCurrentPageState(resetLastScrollPosition);

    const {
        activeSectors,
        handleSectorsFilterUpdate,
        activeServiceTypes,
        handleServiceTypesFilterUpdate,
        activeClientTypes,
        handleClientTypesFilterUpdate,
        handleResetAllActiveFilters,
    } = usePlatformFiltering(resetCurrentPage);

    const { collection, isLoading } = useFetchPlatformOverviewItems(
        currentPage,
        activeSectors,
        activeServiceTypes,
        activeClientTypes
    );

    const value: ContextValue = {
        isLoading,
        collection,
        onPageChange,
        currentPage,
        lastScrollPositionRef,
        resetLastScrollPosition,
        onSectorsFilterChange: handleSectorsFilterUpdate,
        onServiceTypesFilterChange: handleServiceTypesFilterUpdate,
        onClientTypesFilterChange: handleClientTypesFilterUpdate,
        resetAllActiveFilters: handleResetAllActiveFilters,
        activeSectors,
        activeServiceTypes,
        activeClientTypes,
    };

    return (
        <PlatformOverviewContext.Provider value={value}>
            {children}
        </PlatformOverviewContext.Provider>
    );
};

// don't directly expose the context, but expose a custom hook that uses the context interally
export const usePlatformOverviewContext = () =>
    useContext(PlatformOverviewContext);
