import { createClient } from 'contentful';

const space = process.env.GATSBY_CONTENTFUL_SPACE_ID as string;
const accessToken = process.env
    .GATSBY_CONTENTFUL_DELIVERY_ACCESS_TOKEN as string;
const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT as string;

console.assert(!!space, 'No contentful space is set');
console.assert(!!accessToken, 'No contentful access token is set');
console.assert(!!environment, 'No contentful environment is set');

export const client = createClient({
    space,
    accessToken,
    environment,
});
