module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W4SC9JG","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Platformwerk.nl","short_name":"starter","start_url":"/","background_color":"#00a6fb","theme_color":"#00a6fb","display":"minimal-ui","icon":"src/images/platformwerknl.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5fc6e7856e9be608e266eb1d9aa23543"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
