import { ErrorInfo } from 'react';

export function logError(error: Error, errorInfo: ErrorInfo): void {
    // @ts-ignore -> Typescript does not know about Sentry
    if (typeof window.Sentry === 'undefined') {
        console.error('Expecting window to have a Sentry key');

        return;
    }

    try {
        // @ts-ignore -> Typescript does not know about Sentry
        window.Sentry.configureScope(
            (scope: { setExtra: (key: string, info: string) => void }) => {
                Object.keys(errorInfo).forEach((key) => {
                    // @ts-ignore => Typescript does not know error info contents
                    scope.setExtra(key, errorInfo[key]);
                });
            }
        );

        // @ts-ignore -> Typescript does not know about Sentry
        window.Sentry.captureException(error);
    } catch (error) {
        console.error('Something went wrong while pushing to Sentry', error);
    }

    console.error(error, errorInfo);
}
