import React from 'react';
import styles from '../contentBlock.module.scss';

type Props = {
    children: React.ReactNode;
};

const ContentContainer = ({ children }: Props) => (
    <div className={styles.contentContainer}>{children}</div>
);

export default ContentContainer;
