import React from 'react';
import ContentBlock from '../../primitives/contentBlock/ContentBlock';
import ContentBlockMain from '../../primitives/contentBlock/components/ContentBlockMain';
import ContentContainer from '../../primitives/contentBlock/components/ContentContainer';
import { homePath } from '../../../routing/urlGenerator';

const ErrorPage = () => (
    <ContentBlock>
        <ContentBlockMain>
            <ContentContainer>
                <h1>Oeps...Er is iets foutgegaan!</h1>
                <p>Probeer het later nog eens! Of..</p>
                <ul>
                    <li>
                        <a href={homePath}>Ga terug naar de homepage</a>
                    </li>
                    <li>
                        <a href="javascript:history.back(-1);">
                            Ga naar de vorige pagina
                        </a>
                    </li>
                </ul>
            </ContentContainer>
        </ContentBlockMain>
    </ContentBlock>
);

export default ErrorPage;
