import React from 'react';
import styles from '../contentBlock.module.scss';

type Props = {
    children: React.ReactNode;
};

const ContentBlockMain = ({ children }: Props) => (
    <div className={styles.main}>{children}</div>
);

export default ContentBlockMain;
