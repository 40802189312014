import React from 'react';
import styles from './footer.module.scss';
import Heading, { SizeVariationOptions, TagOptions } from '../heading/Heading';
import {
    homePath,
    PageSlug,
    createPageDetailPath,
} from '../../../routing/urlGenerator';
import { Link } from 'gatsby';
import { ReactComponent as FreshheadsLogo } from '../../../images/partners/freshheads.svg';
import { ReactComponent as MartijnAretsLogo } from '../../../images/partners/martijn-arets.svg';
import { ReactComponent as ContentfullLogo } from '../../../images/partners/logo-contentful.svg';
import { ReactComponent as CcIcon } from '../../../images/cc.svg';

// @ts-ignore -> typescript does not read json file
import { fileName as exportFileName } from '../../../databaseExport.json';

type Props = {
    siteTitle: string;
};

const Footer = ({ siteTitle }: Props) => (
    <footer className={styles.footer}>
        <div className="container">
            <div className={styles.footerTop}>
                <div className={styles.col}>
                    <Heading
                        className={styles.heading}
                        tag={TagOptions.h3}
                        size={SizeVariationOptions.h6}
                        flatten
                    >
                        {siteTitle}
                    </Heading>
                    <div className={styles.footerContent}>
                        <ul className={styles.list}>
                            {exportFileName && (
                                <li>
                                    <a
                                        className={styles.listLink}
                                        href={exportFileName}
                                        title="Download database"
                                        download
                                    >
                                        Download database
                                    </a>
                                </li>
                            )}
                            <li>
                                <Link to={homePath} className={styles.listLink}>
                                    Overzicht platformen
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={createPageDetailPath(PageSlug.Contact)}
                                    className={styles.listLink}
                                >
                                    Platform aanmelden
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.col}>
                    <Heading
                        className={styles.heading}
                        tag={TagOptions.h3}
                        size={SizeVariationOptions.h6}
                        flatten
                    >
                        Over
                    </Heading>
                    <div className={styles.footerContent}>
                        <p className={styles.footerParagraph}>
                            Platformen spelen een steeds belangrijkere rol in
                            het samenbrengen van vraag en aanbod van arbeid.
                            Deze website biedt een onafhankelijk overzicht van
                            deze markt en geeft inzicht in de variabelen per
                            platform.
                        </p>
                    </div>
                </div>

                <div className={styles.col}>
                    <Heading
                        className={styles.heading}
                        tag={TagOptions.h3}
                        size={SizeVariationOptions.h6}
                        flatten
                    >
                        Partners
                    </Heading>
                    <div className={styles.footerContent}>
                        <ul className={styles.partnerlist}>
                            <li>
                                <a
                                    href="https://www.freshheads.com/"
                                    title="Freshheads"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FreshheadsLogo />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="http://www.martijnarets.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Martijn Arets"
                                >
                                    <MartijnAretsLogo />
                                </a>
                            </li>
                            <li>
                                <a
                                    className={styles.contentful}
                                    href="https://www.contentful.com"
                                    title="Contentful"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ContentfullLogo />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className={styles.footerBottom}>
                <CcIcon className={styles.ccIcon} />
                <span className={styles.transparant}>
                    Alle content op deze website vallen onder de Creative
                    Commons Naamsvermelding 4.0 Internationaal licentie.
                </span>
            </p>
        </div>
    </footer>
);

export default Footer;
