import qs from 'qs';
import { WindowLocation } from '@reach/router';

type QueryParams = {
    [key: string]: string | number | QueryParams;
};

const extractStringQueryParamFromLocation = (
    location: WindowLocation | Location,
    key: string
): string | null => {
    const data = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    const value = data[key];

    if (value && typeof value === 'string') {
        return value;
    }

    return null;
};

export const extractNumericQueryParamFromLocation = (
    location: WindowLocation,
    key: string
): number | null => {
    const value = extractStringQueryParamFromLocation(location, key);

    return value ? parseInt(value) : null;
};

export const createQueryString = (params: QueryParams) =>
    qs.stringify(params, {
        addQueryPrefix: true,
    });
