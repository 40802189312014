import { PaginatedCollection } from '../types/globalTypes';

export function createEmpty<T>(): PaginatedCollection<T> {
    return {
        items: [],
        noOfResultsPerPage: 0,
        startIndex: 0,
        totalNoOfResults: 0,
    };
}
