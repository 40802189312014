import React from 'react';
import cx from 'classnames';
import styles from './main.module.scss';

type Props = {
    children: React.ReactNode;
};

const Main = ({ children }: Props) => (
    <main className={cx('container', styles.mainWrapper)}>{children}</main>
);

export default Main;
