import React from 'react';
import createClassName from 'classnames';
import styles from './heading.module.scss';

export enum TagOptions {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    span = 'span',
}

export enum SizeVariationOptions {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    base = 'base',
}

export enum StyleOptions {
    base = 'base',
    brand = 'brand',
}

export enum WeightOptions {
    semiBold = 'semiBold',
    bold = 'bold',
    extraBold = 'extraBold',
}

interface Props {
    tag: TagOptions;
    style?: StyleOptions;
    weight?: WeightOptions;
    size?: SizeVariationOptions;
    uppercase?: boolean;
    flatten?: boolean;
    children: React.ReactNode;
    className?: string;
    hasAction?: boolean;
    [key: string]: unknown;
}

const Heading: React.FC<Props> = ({
    tag,
    style = StyleOptions.brand,
    weight = WeightOptions.bold,
    size,
    uppercase = false,
    flatten = false,
    children,
    className: incommingClassName,
    ...otherProps
}) => {
    const className: string = createClassName(
        styles.heading,
        {
            [styles.headingUppercase]: uppercase,
            [styles.headingFlatten]: flatten,
            [styles.headingBase]: style === StyleOptions.base,
            [styles.headingSemiBold]: weight === WeightOptions.semiBold,
            [styles.headingExtraBold]: weight === WeightOptions.extraBold,
            [styles.headingSizeVariantH1]: size === SizeVariationOptions.h1,
            [styles.headingSizeVariantH2]: size === SizeVariationOptions.h2,
            [styles.headingSizeVariantH3]: size === SizeVariationOptions.h3,
            [styles.headingSizeVariantH4]: size === SizeVariationOptions.h4,
            [styles.headingSizeVariantH5]: size === SizeVariationOptions.h5,
            [styles.headingSizeVariantH6]: size === SizeVariationOptions.h6,
            [styles.headingSizeVariantBase]: size === SizeVariationOptions.base,
        },
        incommingClassName
    );

    return React.createElement(tag, { className, ...otherProps }, children);
};

export default Heading;
