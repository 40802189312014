import { useState, useEffect } from 'react';

export default function useToggleVisibility(initialState = true) {
    const [isVisible, setVisibleState] = useState<boolean>(initialState);

    const toggleVisibility = () => {
        setVisibleState(!isVisible);
    };

    useEffect(() => {
        // force visibility state change when initial state prop get's changed for example on window resize
        setVisibleState(initialState);
    }, [initialState]);

    return {
        isVisible,
        toggleVisibility,
    };
}
