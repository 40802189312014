/* eslint-disable @typescript-eslint/camelcase */

import { PlatformListResponseFields } from './../cdnApiResponseTypes.d';
import { PlatformOverviewItemCollection } from './../../model/types/platformOverviewTypes';
import { client } from '../client';
import { createCollectionFromCDNApiResponse } from '../../model/factory/platformOverviewItemFactory';

type PlatformQuery = {
    content_type: string;
    include: number;
    skip: number;
    limit: number;
    'fields.sector.sys.id[in]'?: string;
    'fields.serviceType[in]'?: string;
    'fields.clientType[in]'?: string;
};

export async function fetchAll(
    skip: number,
    limit: number,
    activeSectors: Array<string>,
    activeServiceTypes: Array<string>,
    activeClientTypes: Array<string>
): Promise<PlatformOverviewItemCollection> {
    const query: PlatformQuery = {
        content_type: 'platform',
        include: 2,
        skip,
        limit,
    };

    if (activeSectors.length > 0) {
        query['fields.sector.sys.id[in]'] = activeSectors.join();
    }

    if (activeServiceTypes.length > 0) {
        query['fields.serviceType[in]'] = activeServiceTypes.join();
    }

    if (activeClientTypes.length > 0) {
        query['fields.clientType[in]'] = activeClientTypes.join();
    }

    const data = await client.getEntries<PlatformListResponseFields>(query);

    return createCollectionFromCDNApiResponse(data);
}
