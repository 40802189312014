/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { PlatformOverviewContextProvider } from './src/context/platformOverview/platformOverviewContext';
import { MatchMediaProvider } from 'react-match-media-context';
import 'typeface-montserrat';
import 'typeface-merriweather';
import './src/styles/global.scss';
import 'react-placeholder/lib/reactPlaceholder.css';
import Layout from './src/components/layout/Layout';
import ErrorBoundary from './src/components/errorBoundary/ErrorBoundary';
import { breakpointConfig } from './src/constants/breakpoints';
import Url from 'url-parse';
import { homePath } from './src/routing/urlGenerator';

// ENSURE THIS METHOD MATCHES THE SETUP IN gatsby-ssr.js!!!!
export const wrapPageElement = ({ element }) => {
    // Ensures that all page contents is wrapped in these components. The components below are never unmounted
    return (
        <ErrorBoundary>
            <MatchMediaProvider media={breakpointConfig}>
                <Layout>
                    <PlatformOverviewContextProvider>
                        {element}
                    </PlatformOverviewContextProvider>
                </Layout>
            </MatchMediaProvider>
        </ErrorBoundary>
    );
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
    const currentUrl = new Url(location.href);

    // let homepage handle it's own scroll position, instead of Gatsby
    // standard scroll to top functionality
    // @see https://www.gatsbyjs.org/docs/browser-apis/#shouldUpdateScroll
    if (currentUrl.pathname === homePath) {
        return false;
    }

    return true;
};
