import React from 'react';
import styles from './contentBlock.module.scss';
import createClassname from 'classnames';

type Props = {
    children: React.ReactNode;
    additionalClassName?: string;
};

const ContentBlock = React.forwardRef<HTMLElement, Props>(
    ({ children, additionalClassName }, ref) => (
        <article
            ref={ref}
            className={createClassname(
                styles.contentBlock,
                additionalClassName
            )}
        >
            {children}
        </article>
    )
);

ContentBlock.displayName = 'ContentBlockWithForwardedRef';

export default ContentBlock;
