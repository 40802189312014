import React, { ErrorInfo } from 'react';
import ErrorPage from './components/ErrorPage';
import { logError } from '../../utility/logger';

type Props = {
    children: React.ReactNode;
};

type State = {
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState(
            (currentState: State): State => ({
                ...currentState,
                error,
                errorInfo,
            })
        );

        logError(error, errorInfo);
    }

    public render() {
        const { error, errorInfo } = this.state;

        if (error || errorInfo) {
            return <ErrorPage />;
        }

        return this.props.children;
    }
}
