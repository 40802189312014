import { useLayoutEffect } from 'react';

let originalOverflow: string | null = null;

const lock = () => {
    originalOverflow = window.getComputedStyle(document.documentElement)
        .overflow;
    document.documentElement.style.overflow = 'hidden';
};

const unlock = () => {
    if (originalOverflow) {
        document.documentElement.style.overflow = originalOverflow;
    }

    originalOverflow = null;
};

const useLockHtmlScroll = (enabled = true) => {
    useLayoutEffect(() => {
        if (enabled) {
            lock();

            return () => unlock();
        }
    }, [enabled]);
};

export default useLockHtmlScroll;
