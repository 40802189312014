// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-content-page-detail-data-provider-tsx": () => import("./../../../src/templates/ContentPageDetailDataProvider.tsx" /* webpackChunkName: "component---src-templates-content-page-detail-data-provider-tsx" */),
  "component---src-templates-platform-detail-data-provider-tsx": () => import("./../../../src/templates/PlatformDetailDataProvider.tsx" /* webpackChunkName: "component---src-templates-platform-detail-data-provider-tsx" */)
}

