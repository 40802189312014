import { PlatformListResponse } from '../../contentful/cdnApiResponseTypes';
import {
    PlatformOverviewSector,
    PlatformOverviewItemCollection,
    PlatformOverviewItem,
} from '../types/platformOverviewTypes';

/**
 * Creates a normalized and simplified view model of the response given by the API, that is easier
 * to use, has less nesting and filters out non-published items.
 */
export function createCollectionFromCDNApiResponse(
    response: PlatformListResponse
): PlatformOverviewItemCollection {
    const items = response.items.map<PlatformOverviewItem>((cursorItem) => {
        const sectors = cursorItem.fields.sector
            .map((sectorEntry) => {
                // undefined when a sector was assigned, but it swas not published
                if (!sectorEntry) {
                    return null;
                }

                const parentSectorEntries = sectorEntry.fields.parentSector.filter(
                    (cursorParentSector) => !!cursorParentSector
                );

                const parentSectorEntry = parentSectorEntries.pop();

                // undefined when a parent sector was assigned, but it was not published
                if (!parentSectorEntry) {
                    return null;
                }

                return {
                    ...sectorEntry.fields,
                    parentSector: parentSectorEntry.fields,
                };
            })
            .filter((sector) => {
                // filter out any null variables (see above)
                return !!sector;
            });

        return {
            id: cursorItem.sys.id,
            title: cursorItem.fields.title,
            slug: cursorItem.fields.slug,
            sectors: sectors as PlatformOverviewSector[],
            introduction: cursorItem.fields.introduction,
            logo: cursorItem.fields.logo.fields,
        };
    });

    return {
        items,
        noOfResultsPerPage: response.limit,
        startIndex: response.skip,
        totalNoOfResults: response.total,
    };
}
