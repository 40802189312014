import { Link } from 'gatsby';
import React from 'react';
import { homePath } from '../../../routing/urlGenerator';
import styles from './header.module.scss';
import MainNavigation from '../../mainNavigation/MainNavigation';

type Props = {
    siteTitle: string;
};

const Header = ({ siteTitle }: Props) => (
    <header className={styles.header}>
        <div className="container">
            <div className={styles.headerInner}>
                <Link to={homePath} className={styles.logo}>
                    {siteTitle}
                </Link>
                <MainNavigation />
            </div>
        </div>
    </header>
);

export default Header;
