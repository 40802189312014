export type BreakpointsConfigMatch = {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
};

// keep in line with sass specified breakpoints
export const breakpointConfig = {
    xs: {
        minWidth: '0px',
        maxWidth: '575px',
        isDefaultValue: true,
    },
    sm: {
        minWidth: '576px',
        maxWidth: '767px',
        isDefaultValue: false,
    },
    md: {
        minWidth: '768px',
        maxWidth: '991px',
        isDefaultValue: false,
    },
    lg: {
        minWidth: '992px',
        maxWidth: '1199px',
        isDefaultValue: false,
    },
    xl: {
        minWidth: '1200px',
        isDefaultValue: false,
    },
};
