import React from 'react';
import { Link } from 'gatsby';
import {
    homePath,
    createPageDetailPath,
    PageSlug,
} from '../../routing/urlGenerator';

import styles from './mainNavigation.module.scss';
import useLockHtmlScroll from '../../hooks/useLockHtmlScroll';
import useMainNavigationVisibility from './hooks/useMainNavigationVisibility';

const MainNavigation = () => {
    const {
        hasMobileMenu,
        isMenuOpen,
        toggleVisibility,
    } = useMainNavigationVisibility();

    useLockHtmlScroll(isMenuOpen && hasMobileMenu);

    // close menu overlay on mobile when navigating to items
    const onMenuItemClick = () => {
        if (isMenuOpen) {
            toggleVisibility();
        }
    };

    return (
        <nav>
            <button
                aria-label="Menu"
                aria-controls="navigation"
                aria-expanded={isMenuOpen && hasMobileMenu ? 'true' : 'false'}
                onClick={toggleVisibility}
                className={styles.navigationTrigger}
            >
                <span className={styles.navigationTriggerHamburger}></span>
            </button>
            <div className={styles.navigation} id="navigation">
                <Link
                    to={homePath}
                    className={styles.navigationLink}
                    onClick={onMenuItemClick}
                >
                    Home
                </Link>
                <Link
                    to={createPageDetailPath(PageSlug.About)}
                    className={styles.navigationLink}
                    onClick={onMenuItemClick}
                >
                    Over
                </Link>
                {/* @todo add infographic to this page and uncomment again (PWNL-65)
                <Link
                    to={createPageDetailPath(PageSlug.CollaborativeEconomy)}
                    className={styles.navigationLink}
                    onClick={onMenuItemClick}
                >
                    Kluseconomie
                </Link>
                */}
                <Link
                    to={createPageDetailPath(PageSlug.EnglishAbout)}
                    className={styles.navigationLink}
                    onClick={onMenuItemClick}
                >
                    English
                </Link>
                <Link
                    to={createPageDetailPath(PageSlug.Contact)}
                    className={styles.navigationLink}
                    onClick={onMenuItemClick}
                >
                    Contact
                </Link>
            </div>
        </nav>
    );
};

export default MainNavigation;
